<template>
  <div>
    <a-card title="申请信息">
      <a-descriptions>
        <a-descriptions-item label="姓名">
          {{ info.truename }}
        </a-descriptions-item>
        <a-descriptions-item label="身份证号">
          {{ info.idcard }}
        </a-descriptions-item>
        <a-descriptions-item label="手机号码">
          {{ info.mobile }}
        </a-descriptions-item>
        <a-descriptions-item label="客户信息备注">
          {{ info.remarks }}
        </a-descriptions-item>
      </a-descriptions>
    </a-card>
    <a-card title="录入信息">
      <a-form-model
        :model="form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol">
        <a-row v-if="hidesetmeal">
          <a-col :span="12">
            <a-form-model-item label="套餐类别">
              <a-select
                v-model="form.mealId"
              >
                <a-select-option v-for="item in setmealdata" :key="item.id" :value="item.id" >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="B端渠道">
              <a-select
                name="channelId"
                v-model="form.channelId"
                @change="channelProduct"
              >
                <a-select-option v-for="item in channelData" :key="item.channelId" :value="item.channelId">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="B端产品">
              <a-select
                v-model="form.channelProductId"
                name="channelProductId"
              >
                <a-select-option
                  v-for="item in productdata"
                  :key="item.channelProductId"
                  :value="item.channelProductId">
                  {{ item.name }}-{{ item.productName }}-{{ item.productChildName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="使用地区">
              <tree-select
                v-model="form.regionId"
                dropdownClassName="dropdownSelect"
                :replaceFields="RegreplaceFields"
                :tree-data="RegtreeData"
                allow-clear
                placeholder="请选择"
                @change="changeRegionhander"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="意向医院">
              <a-select
                v-model="form.nosoId"
                @change="nosochoose"
              >
                <a-select-option v-for="item in nosodata" :key="item.id" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="医院地址">
              <a-select
                v-model="form.addressId"
                aria-placeholder="请选择地址"
              >
                <a-select-option v-for="item in addlistdata" :key="item.addressId" :value="item.addressId">
                  {{ item.address }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12" v-if="hideDep">
            <a-form-item
              label="意向科室"
            >
              <a-select
                v-model="form.nosoDepId"
                name="nosoDepId"
                @change="nosoDephander"
              >
                <a-select-option v-for="item in nosodepdata" :key="item.nosoDepId" :value="item.nosoDepId">
                  {{ item.name }}- {{ item.depName }} - {{ item.sectionName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12" v-if="hideDoctor">
            <a-form-model-item label="意向医生">
              <a-select
                v-model="form.doctorId"
                name="doctorId"
              >
                <a-select-option v-for="item in doctordata" :key="item.doctorId" :value="item.doctorId">
                  {{ item.trueName }}- {{ item.position }} - {{ item.lecturerName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            .
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="体检时间">
              <a-date-picker
                show-time
                format="YYYY-MM-DD HH:mm"
                v-model="form.subscribeDate"
                placeholder="体检时间"/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="陪诊人员">
              <a-select
                v-model="form.escortId"
              >
                <a-select-option v-for="item in escortdata" :key="item.id" :value="item.id">
                  {{ item.truename }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">

          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-model-item label="注意事项">
              <a-input v-model="form.descinfo" type="textarea"/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-model-item label="注意事项模板">
              <a-select
                name="escortId"
                @change="selectTemplate"
              >
                <a-select-option v-for="(item,index) in matterdata" :key="item.id" :value="index">
                  {{ item.title }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>

      </a-form-model>
    </a-card>

  </div>

</template>
<script>
import { formatterDate } from '@/utils/util'
import { getById } from '@/api/order'
import { cityList as RegAllList } from '@/api/region'
import { allList as nosodochiumList, addressList } from '@/api/nosodochium'
import { allList as matterList } from '@/api/matter'
import { allList as escortList } from '@/api/escort'

import { TreeSelect } from 'ant-design-vue'
import moment from 'moment'
import { allList as channelList } from '@/api/channel'
import { allList as channelproductlList } from '@/api/channelproduct'
import { allList as doctorList } from '@/api/doctor'
import { allList as nosodochiumdeplList } from '@/api/nosodochiumdep'
import { allList as setmealList } from '@/api/setmeal'

export default {
  components: {
    TreeSelect
  },
  filters: {
    dataFormat (msg) {
      return formatterDate(msg)
    }
  },
  data () {
    return {
      orderId: 0,
      channelData: [],
      productdata: [],
      RegtreeData: [],
      doctordata: [],
      nosodata: [],
      nosodepdata: [],
      matterdata: [],
      escortdata: [],
      addlistdata: [],
      info: {
        truename: undefined
      },
      formInline: {
        user: '',
        password: ''
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      RegreplaceFields: { children: 'children', title: 'name', key: 'regionId', value: 'regionId' },
      setmealdata: [],
      hidesetmeal: false,
      hideDep: false,
      hideDoctor: false,
      hideThum: false,
      form: {
        channelId: undefined,
        channelProductId: undefined,
        doctorId: undefined,
        nosoDepId: undefined,
        subscribeDate: undefined,
        regionId: undefined,
        tiyandate: undefined,
        escortId: undefined,
        nosoId: undefined,
        addressId: undefined,
        address: undefined,
        descinfo: undefined,
        mealId: undefined
      }
    }
  },
  mounted () {
    this.getRegAllList()
    this.getChannelList()
    this.getMatterList()
    this.getSetmealList()
  },
  methods: {
    setOrderId (orderId) {
      this.orderId = orderId
      getById({ orderId: orderId })
        .then(res => {
          this.info = res.result.info
          const subscribeDate = moment(this.info.subscribeDate)

          this.form = {
            channelId: this.info.channelId,
            channelProductId: this.info.channelProductId,
            doctorId: this.info.doctorId,
            nosoDepId: this.info.nosoDepId,
            subscribeDate: subscribeDate,
            orderId: orderId,
            regionId: this.info.regionId,
            escortId: this.info.escortId,
            nosoId: this.info.nosoId,
            addressId: this.info.addressId,
            descinfo: this.info.descinfo,
            orderType: this.info.orderType,
            mealId: this.info.mealId
          }
          // 医院
          this.getProductlList(this.info.channelId)
          this.getNosodochiumList(this.info.regionId)
          this.getAddressList(this.info.nosoId)
          this.getNosodochiumdeplList(this.info.nosoId) // 科室
          this.getDoctorList(this.info.nosoDepId) // 医生
          this.ChangeOrderValue(this.info.orderType)
          this.getEscortList(this.info.regionId)
          this.$forceUpdate()
        })
    },
    handleSubmit (e) {
      console.log(this.formInline)
    },
    getSetmealList () {
      setmealList().then(res => {
        this.setmealdata = res.result
      })
    },
    getRegAllList () {
      RegAllList().then(res => {
        this.RegtreeData = res.result
      })
    },
    getChannelList () {
      channelList().then(res => {
        this.channelData = res.result
      })
    },
    getNosodochiumList (regionId) {
      nosodochiumList({ regionIds: regionId }).then(res => {
        this.nosodata = res.result
        this.$forceUpdate()
      })
    },
    getMatterList () {
      matterList().then(res => {
        this.matterdata = res.result
      })
    },
    getNosodochiumdeplList (nosoId) {
      nosodochiumdeplList({ nosoId: nosoId }).then(res => {
        this.nosodepdata = res.result
        this.$forceUpdate()
      })
    },
    getEscortList (regionId) {
        escortList({ 'like__regionIds': ',' + regionId + ']' }).then(res => {
            this.escortdata = res.result
        })
    },
    nosochoose (nosoId) {
      this.form.nosoDepId = undefined
      this.form.addressId = undefined
      this.form.doctorId = undefined
      this.getAddressList(nosoId)
      this.getNosodochiumdeplList(nosoId)
    },
    getAddressList (nosoId) {
      if (nosoId > 0) {
        addressList({ nosoId: nosoId }).then(res => {
          this.addlistdata = res.result.data
        })
      }
    },

    getDoctorList (value) {
      doctorList({ nosoDepId: value }).then(res => {
        this.doctordata = res.result
        this.$forceUpdate()
      })
    },
    selectTemplate (index) {
      this.form.descinfo = this.matterdata[index].content
    },
    onSubmit () {
      this.$emit('submityuyueForm', this.form)
    },
    channelProduct (value) {
      this.form.channelProductId = undefined
      this.getProductlList(value)
    },
    getProductlList (channelId) {
      channelproductlList({ channelId: channelId, orderType: this.form.orderType }).then(res => {
        this.productdata = res.result
        this.$forceUpdate()
      })
    },
    changeRegionhander (value, label, extra) {
      this.form.nosoId = undefined
      this.form.nosoDepId = undefined
      this.form.addressId = undefined
      this.form.doctorId = undefined
      this.getNosodochiumList(value)
      this.getEscortList(value)
    },
    nosoDephander (value) {
      this.form.doctorId = undefined
      this.getDoctorList(value)
    },
    ChangeOrderValue (value) {
      if (value === 1) {
        this.hideDep = false
        this.hideDoctor = false
        this.hidesetmeal = true
      } else {
        this.hideDep = true
        this.hideDoctor = true
        this.hidesetmeal = false
      }
      if (value === 3) {
        this.hideThum = true
      } else {
        this.hideThum = false
      }
    }
  }
}
</script>
